import { render, staticRenderFns } from "./FormatPriceInput.vue?vue&type=template&id=38a609a3&scoped=true&"
import script from "./FormatPriceInput.vue?vue&type=script&lang=js&"
export * from "./FormatPriceInput.vue?vue&type=script&lang=js&"
import style0 from "./FormatPriceInput.vue?vue&type=style&index=0&id=38a609a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a609a3",
  null
  
)

export default component.exports